// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bedroom-js": () => import("./../../../src/pages/bedroom.js" /* webpackChunkName: "component---src-pages-bedroom-js" */),
  "component---src-pages-home-entertainment-js": () => import("./../../../src/pages/home-entertainment.js" /* webpackChunkName: "component---src-pages-home-entertainment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-living-js": () => import("./../../../src/pages/living.js" /* webpackChunkName: "component---src-pages-living-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-wardrobe-js": () => import("./../../../src/pages/wardrobe.js" /* webpackChunkName: "component---src-pages-wardrobe-js" */)
}

